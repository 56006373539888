import { InteractionType, RedirectRequest } from '@azure/msal-browser';
import { MsalAuthenticationResult, MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { msalInstance } from 'index';
import { ElementType, ReactNode, memo, useEffect, useRef } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { CustomNavigationClient } from './NavigationClient';

type Props = {
  children: ReactNode;
};

const AuthRequired = ({ children }: Props) => {
  const { instance, accounts, inProgress } = useMsal();
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  msalInstance.setNavigationClient(navigationClient);

  if (
    !window.location.href.includes('/dashboard') ||
    /^.*\/dashboard\?plantId=.*/.test(window.location.href)
  ) {
    localStorage.setItem('redirectionUrl', window.location.href);
  }

  const rememberedStorage = localStorage
    .getItem('redirectionUrl')
    ?.replace('www.', '')
    ?.replace(process.env.REACT_APP_DOMAIN as string, '');

  const remembered = rememberedStorage === '/' ? '/dashboard' : rememberedStorage;
  const rememberMeSSO = localStorage.getItem('domain_hint');

  const loginRequest: RedirectRequest = {
    scopes: [],
    redirectUri: '/dashboard',
    ...(rememberMeSSO &&
      rememberMeSSO !== 'undefined' && {
        extraQueryParameters: {
          domain_hint: JSON.parse(rememberMeSSO),
        },
      }),
  };

  const redirectInitiated = useRef(false);

  useEffect(() => {
    if (
      !redirectInitiated.current &&
      accounts.length === 0 &&
      rememberMeSSO &&
      rememberMeSSO !== 'undefined' &&
      inProgress === 'none'
    ) {
      redirectInitiated.current = true;
      localStorage.removeItem('domain_hint');
    }
  }, [accounts, rememberMeSSO, instance, loginRequest, inProgress]);

  const ErrorComponent: ElementType<MsalAuthenticationResult> = () => {
    localStorage.removeItem('domain_hint');
    instance.loginRedirect();
    return <></>;
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      errorComponent={ErrorComponent}
    >
      {remembered ? (
        <>
          <Redirect to={remembered} />
          {children}
        </>
      ) : (
        <>{children}</>
      )}
    </MsalAuthenticationTemplate>
  );
};

export default memo(AuthRequired);
